import { maxBy, orderBy } from 'lodash';
import { Angulartics2 } from 'angulartics2';
import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    ViewChild,
    ElementRef,
    OnChanges,
    SimpleChanges,
    Inject,
} from '@angular/core';

import { Pill } from '../pill';
import {
    GLOBAL_FILTER,
    removeFilters,
    addFilter,
    removePagination,
    removeAllFilters,
    DEFAULT_NAMESPACE,
    IActionRemoveFilters,
} from '../../redux/actions';
import { getFilterIdsByKey, createFilter } from '../../redux/utils';
import { KEY } from '../key.enum';
import { GfbEditorComponent, FilterChanges } from '../gfb-editor/gfb-editor.component';
import { Resource, GFBEntity, ProgramType, FeatureFlagDxpService } from '@pure1/data';
import { GfbAutocompleteService, GfbFilterMode } from '../../services/gfb-autocomplete.service';
import { IStateFilter, IState } from '../../redux/pure-redux.service';
import { NgRedux } from '../../redux/ng-redux.service';
import { Action } from 'redux';
import { ampli } from '../../ampli';
import { WINDOW } from '../../app/injection-tokens';
import { FeatureNames } from '../../model/FeatureNames';

const NO_SELECTION = -1;

@Component({
    selector: 'gfb',
    templateUrl: 'gfb.component.html',
})
export class GfbComponent implements OnInit, OnDestroy, OnChanges {
    @Input() readonly barId: string;
    @Input() readonly entities: GFBEntity[];
    @Input() defaultEntity: GFBEntity; // if not specified, will default to last entity in [entities]
    @Input() defaultKey: string;
    // Indicates if the gfb is filtering for members of a view or group rather than default use
    @Input() readonly filterMode: GfbFilterMode = 'default';
    @Input() readonly filterToOrgId: number = null;
    // If input value is not null, showing subscriptions with matched programType ONLY on gfb. Otherwise will show all subscriptions
    @Input() readonly filterToProgramType: ProgramType = null;
    @Input() allowPinning: boolean = true; // TODO: Make this readonly...
    @Input() readonly disabled: boolean = false;
    @Input() readonly supportStatusFilterOption: SupportStatusFilterOption;

    @ViewChild('gfbContainer', { static: true }) readonly gfbContainer: ElementRef;
    @ViewChild(GfbEditorComponent, { static: true }) readonly gfbEditor: GfbEditorComponent;

    pills: Pill[] = [];
    editFilters: IStateFilter[] = [];
    selectedPillIndex = NO_SELECTION;
    isSupportStatusFilterFFEnabled = false;

    private fullKeyToPill: Map<string, Pill> = new Map();
    private unsubscribeFromRedux: Function;

    constructor(
        @Inject(WINDOW) private window: Window,
        private angulartics2: Angulartics2,
        private ngRedux: NgRedux<IState>,
        private autocomplete: GfbAutocompleteService,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {}

    ngOnInit(): void {
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.ARRAYS_CTRL_RETURN_ACTIVE_ONLY_M15)
            .subscribe(feature => {
                this.isSupportStatusFilterFFEnabled = feature?.enabled === true;
            });

        if (!this.defaultEntity) {
            this.defaultEntity = this.entities[this.entities.length - 1];
        }
        if (!this.defaultKey) {
            this.defaultKey = 'name';
        }
        if (this.filterMode !== 'default') {
            this.allowPinning = false;
            if (this.filterMode === 'views') {
                this.autocomplete.enableViewFiltering(this.filterToOrgId, this.filterToProgramType);
            } else if (this.filterMode === 'metric_groups') {
                this.autocomplete.enableMetricGroups(this.filterToOrgId);
            }
        }
        this.setupRedux();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterToOrgId) {
            this.autocomplete.setOrgScope(this.filterToOrgId);
        }
        if (changes.filterToProgramType) {
            this.autocomplete.setProgramTypeFilter(this.filterToProgramType);
        }
    }

    ngOnDestroy(): void {
        if (this.unsubscribeFromRedux) {
            this.unsubscribeFromRedux();
        }
    }

    add(filterChanges: FilterChanges): void {
        const filters = filterChanges.filters;
        filters.forEach(filter => this.toPill(filter, false));
        const adds = filters.map(filter =>
            addFilter(this.barId, createFilter(filter.entity, filter.namespace, filter.key, filter.value)),
        );
        this.ngRedux.dispatch([...adds, removePagination(this.barId)]);
        if (filterChanges.refocusing) {
            // if we commit using keyboard (i.e. Enter key), we should focus back on the GfbEditor
            this.selectEditor();
        }
    }

    addDefaultFilter(value: string): void {
        this.add({
            filters: [createFilter(this.defaultEntity, DEFAULT_NAMESPACE, this.defaultKey, value)],
            refocusing: false,
        });
    }

    trackByPillId(index: number, pill: Pill): string {
        return pill.pillId();
    }

    edit(i: number): void {
        const pill = this.pills[i];
        if (!pill) {
            return;
        }

        this.angulartics2.eventTrack.next({
            action: `${pill.entity().toLocaleLowerCase()} GFB pill edited`,
            properties: { category: 'Action', label: pill.filters()[0].displayKey },
        });

        this.editFilters = pill.filters();
        this.remove(i);
    }

    onClick($event: MouseEvent): void {
        $event.stopImmediatePropagation();
        if (
            $event.relatedTarget === this.gfbEditor.keyInput.nativeElement ||
            $event.relatedTarget === this.gfbEditor.valueInput.nativeElement
        ) {
            this.selectEditor(true);
        } else {
            this.selectEditor(false);
        }
    }

    onBlur(): void {
        this.selectedPillIndex = NO_SELECTION;
    }

    onKeyDown(event: KeyboardEvent): void {
        switch (event.key) {
            case KEY.left:
                this.selectPrev();
                this.angulartics2.eventTrack.next({
                    action: `${this.barId} GFB keyboard pill nav`,
                    properties: { category: 'Action', label: 'left' },
                });
                break;
            case KEY.right:
                this.selectNext();
                this.angulartics2.eventTrack.next({
                    action: `${this.barId} GFB keyboard pill nav`,
                    properties: { category: 'Action', label: 'right' },
                });
                break;
            case KEY.backspace:
            case KEY.delete:
                if (this.pills[this.selectedPillIndex]) {
                    this.angulartics2.eventTrack.next({
                        action: `${this.barId} GFB keyboard pill delete`,
                        properties: { category: 'Action', label: this.pills[this.selectedPillIndex].fullKey() },
                    });
                }
                this.remove(this.selectedPillIndex);
                break;
            case KEY.tab:
                this.selectEditor();
                event.preventDefault(); // Don't focus at a random place
                break;
            case KEY.escape:
                this.onBlur();
                break;
            case KEY.space:
                this.togglePin(this.selectedPillIndex);
                if (this.pills[this.selectedPillIndex]) {
                    this.angulartics2.eventTrack.next({
                        action: `${this.barId} GFB keyboard pill toggle`,
                        properties: { category: 'Action', label: this.pills[this.selectedPillIndex].fullKey() },
                    });
                }
                event.preventDefault(); // Don't scroll
                break;
            default:
                break;
        }
    }

    onSelectionChange(): void {
        if (this.pills.length > 0) {
            // remove focus from GfbEditor by focusing on GFB
            this.gfbContainer.nativeElement.focus();
            this.selectedPillIndex = this.pills.length - 1;
        } else {
            this.selectEditor(true);
        }
    }

    selectPill(i: number): void {
        this.selectedPillIndex = i;
    }

    togglePin(i: number): void {
        const pill = this.pills[i];
        if (!pill?.pinnable) {
            return;
        }

        this.angulartics2.eventTrack.next({
            action: `${pill.entity().toLocaleLowerCase()} GFB pill ${pill.pinned ? 'unpinned' : 'pinned'}`,
            properties: { category: 'Action', label: pill.filters()[0]?.displayKey },
        });

        const actions: Action[] = [];
        // remove the existing pill from whichever bar it comes from
        actions.push(this.actionForRemove(pill));

        const newBarId = pill.pinned ? this.barId : GLOBAL_FILTER;
        let localFilters: IStateFilter[] = [];
        if (pill.pinned) {
            // try to remove all unpinned pill and add them after this pill
            localFilters = this.ngRedux.getState().filters[this.barId];
            if (localFilters) {
                const ids = localFilters.map(p => p.id);
                actions.push(removeFilters(this.barId, ids));
            }
        }
        pill.filters().forEach(filter =>
            actions.push(addFilter(newBarId, createFilter(filter.entity, filter.namespace, filter.key, filter.value))),
        );
        if (localFilters) {
            localFilters.forEach(filter =>
                actions.push(
                    addFilter(newBarId, createFilter(filter.entity, filter.namespace, filter.key, filter.value)),
                ),
            );
        }

        this.ngRedux.dispatch(actions);
        pill.pinned = !pill.pinned;
    }

    remove(i: number): void {
        const pill = this.pills[i];

        if (!pill || !this.fullKeyToPill.has(pill.fullKey())) {
            return;
        }

        this.angulartics2.eventTrack.next({
            action: `${pill.entity().toLocaleLowerCase()} GFB pill removed`,
            properties: { category: 'Action', label: pill.filters()[0]?.displayKey },
        });

        this.pills = this.pills.filter(p => p !== pill);
        this.fullKeyToPill.delete(pill.fullKey());

        if (this.selectedPillIndex >= this.pills.length) {
            // when we delete the last pill while it is selected, then select the next-to-last pill
            // (which will be the new last pill, after deletion)
            this.selectedPillIndex = this.pills.length - 1;
        }
        if (this.selectedPillIndex === NO_SELECTION) {
            // we have deleted the only pill in GFB, which was selected
            this.selectEditor();
        }

        this.ngRedux.dispatch([this.actionForRemove(pill), removePagination(this.barId)]);
    }

    removeAllPills(event: MouseEvent): void {
        // Don't refocus the gsb
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();

        this.angulartics2.eventTrack.next({
            action: `GFB pills clear all`,
            properties: { category: 'Action', label: this.pills.length },
        });

        this.fullKeyToPill.clear();
        this.pills = [];
        this.ngRedux.dispatch([removeAllFilters(this.barId), removeAllFilters(GLOBAL_FILTER)]);
    }

    private setupRedux(): void {
        let isFirstLoad = true;

        const updatePills = () => {
            const localFilters: IStateFilter[] = this.ngRedux.getState().filters[this.barId];
            const globalFilters: IStateFilter[] = this.allowPinning
                ? this.ngRedux.getState().filters[GLOBAL_FILTER]
                : null;
            this.fullKeyToPill.clear();
            this.pills = [];
            if (globalFilters) {
                globalFilters
                    .filter(filter => this.validateFilter(filter))
                    .forEach(filter => this.toPill(filter, true));
            }
            if (localFilters) {
                localFilters
                    .filter(filter => this.validateFilter(filter))
                    .forEach(filter => this.toPill(filter, false));
            }
            // cannot use Array.prototype.sort, since that is not stable
            // for here, we don't want to change the order pill's state doesn't change.
            this.pills = orderBy(this.pills, ['pinned', 'pinnable'], ['desc', 'desc']);

            // Track tag usage in Amplitude
            const getPillKeys = (cloudTags: boolean) => {
                return this.pills
                    .filter(p => (cloudTags ? p.namespace() === 'pure1' : p.namespace() !== 'pure1'))
                    .map(p => p.key())
                    .filter(key => key?.length > 0)
                    .sort((a, b) => a.localeCompare(b));
            };

            const pillWithMostKeys = maxBy(this.pills, p => p.filters()?.length);

            ampli.gfbTagUsage({
                'is initialization event': isFirstLoad,
                'cloud tags': getPillKeys(true),
                'array properties': getPillKeys(false),
                'total key count': this.pills.length,
                'page pathname': this.window.location.pathname,
                'most values key': pillWithMostKeys?.key(),
                'most values count': pillWithMostKeys?.filters()?.length,
            });

            isFirstLoad = false;
        };

        updatePills();
        // connect ngRedux to store
        this.unsubscribeFromRedux = this.ngRedux.subscribe(updatePills);
    }

    private actionForRemove(pill: Pill): IActionRemoveFilters {
        const currentBarId = pill.pinned ? GLOBAL_FILTER : this.barId;
        const filter = pill.filters()[0];
        const barFilters = this.ngRedux.getState().filters[currentBarId];
        const ids = getFilterIdsByKey(barFilters, filter.entity, filter.key, filter.namespace);
        return removeFilters(currentBarId, ids);
    }

    private selectEditor(refocusing = false): void {
        this.selectedPillIndex = NO_SELECTION;
        this.gfbEditor.onFocus(refocusing);
    }

    private selectPrev(): void {
        if (this.selectedPillIndex > 0) {
            this.selectedPillIndex--;
        }
    }

    private selectNext(): void {
        if (this.selectedPillIndex < this.pills.length - 1) {
            this.selectedPillIndex++;
        } else {
            this.selectEditor();
        }
    }

    private toPill(filter: IStateFilter, pinned: boolean): void {
        const newPill = new Pill([filter], pinned);
        if ((filter.entity === 'arrays' || filter.entity === 'subscription license') && this.allowPinning) {
            newPill.pinnable = true;
        }
        if (this.fullKeyToPill.has(newPill.fullKey())) {
            const prevPill = this.fullKeyToPill.get(newPill.fullKey());
            prevPill.merge(newPill);
        } else {
            this.pills.push(newPill);
            this.fullKeyToPill.set(newPill.fullKey(), newPill);
        }
    }

    private validateFilter(filter: IStateFilter): boolean {
        return !!filter.entity && !!filter.id && !!filter.key && !!filter.value;
    }
}

<pureui-spinner class="p-loading" [pureuiShow]="loading || chartLoading"></pureui-spinner>
<banners></banners>

<appliance-genealogy-totals [data]="unfilteredData"></appliance-genealogy-totals>

<section class="action-bar">
    <gfb
        class="btn-group flex-btn-group flex-btn-group-first"
        [barId]="barId"
        [entities]="GFB_ENTITIES"
        [defaultKey]="DEFAULT_KEY"
        [defaultEntity]="DEFAULT_ENTITY"
        supportStatusFilterOption="all_arrays"
    >
    </gfb>
    <div class="table-top-actions">
        <div class="actions-left-section">
            <div class="customize-options">
                <genealogy-sort-dropdown
                    [sortOptions]="sortOptions"
                    [sortState]="sortState"
                    (sortChange)="onSort($event)"
                ></genealogy-sort-dropdown>
            </div>
            <div class="export-action" ngbTooltip="Export" container="body">
                <ng-template #exportModal let-modal>
                    <appliance-genealogy-export-modal
                        [modal]="modal"
                        [selectedOption]="selectedExportOption"
                        [entityFilter]="applianceFilter"
                        [totalFiltered]="data?.length"
                        [totalUnfiltered]="totalUnfiltered"
                    >
                    </appliance-genealogy-export-modal>
                </ng-template>
                <export-button
                    class="export-btn"
                    [showNewIcon]="true"
                    [showText]="false"
                    [useSpinner]="true"
                    (updateCounts)="updateExportOptionCounts($event.optionsMap)"
                    [exportOptions]="listViewExportOptions"
                    (onClickExport)="onClickExport($event.option)"
                >
                </export-button>
            </div>
        </div>
        <div class="actions-right-section">
            <div class="indicators">
                <div class="option-info" *ngFor="let event of events">
                    <pureui-svg class="legend-icon" height="12" [svg]="event | genealogyEventIcon"></pureui-svg>
                    <div class="option-text">{{ event | genealogyEventDisplayName }}</div>
                </div>
            </div>
            <genealogy-display-options
                [selectedEvents]="eventTypeFilters"
                [eventOptions]="eventOptions"
                (applyOptions)="onApplyDisplayOptions($event)"
            >
            </genealogy-display-options>
            <div
                class="legend-info"
                hiveTooltip
                hiveTooltipDescription="As of October 2023, capacity metrics are aligned with Workload Planner.
                 Historical information before the blue line may have slight variance.
                 Please note that some events that occurred during the migration period of October 2023 may be duplicated."
                hiveTooltipPosition="topRight"
            >
                <span class="info-content">Capacity (Raw)</span>
                <pureui-svg class="info-icon pstg-action-icon" svg="info-circle.svg" width="13"> </pureui-svg>
            </div>
        </div>
    </div>
</section>

<appliance-genealogy-chart [genealogies]="!loading && filteredData" (loading)="(chartLoading)">
</appliance-genealogy-chart>

<div
    #gfbContainer
    class="gfb"
    (click)="onClick($event)"
    (keydown)="onKeyDown($event)"
    (blur)="onBlur()"
    tabindex="-1"
    [ngClass]="{ disabled: disabled }"
>
    <pureui-svg [svg]="'funnel.svg'" [height]="12" class="gfb-icon"></pureui-svg>

    <gfb-pill
        *ngFor="let pill of pills; let i = index; trackBy: trackByPillId"
        [ngClass]="{ 'pill-selected': selectedPillIndex === i }"
        [pill]="pill"
        [disabled]="disabled"
        [supportStatusFilterOption]="supportStatusFilterOption"
        (edit)="edit(i)"
        (togglePin)="togglePin(i)"
        (remove)="remove(i)"
    ></gfb-pill>

    <gfb-editor
        [entities]="entities"
        [editFilters]="editFilters"
        [pills]="pills"
        [disabled]="disabled"
        [supportStatusFilterOption]="isSupportStatusFilterFFEnabled ? supportStatusFilterOption : 'all_arrays'"
        (filtersChange)="add($event)"
        (selectionChange)="onSelectionChange()"
        (defaultFilter)="addDefaultFilter($event)"
    ></gfb-editor>

    <span *ngIf="pills.length > 0" class="gfb-remove-all" (click)="removeAllPills($event)">
        Clear All
        <pureui-svg class="pstg-action-icon" [svg]="'x-action.svg'" [height]="6"></pureui-svg>
    </span>
</div>
